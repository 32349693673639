@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/breakpoints";

.preview-container {
  position: relative;
  width: 100%;
  height: 100%;

  .img {
    width: 100%;
    box-shadow: 3px 4px 10px 0 rgb(0 0 0 / 15%);
    border-radius: 1rem;
  }

  .bar {
    display: none;
    width: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    :global(circle) {
      fill: none;
      stroke: $green-400;
      stroke-width: 11%;
    }
  }

  .retry {
    display: none;
    color: $gray-200;
    font-size: 3rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
  }

  &.uploading {
    .img {
      filter: blur(3px);
    }

    .bar {
      display: block;
    }
  }

  &.is-error {
    .img {
      filter: blur(3px);
    }

    .retry {
      display: block;
    }
  }
}
