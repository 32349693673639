@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/breakpoints";

.product-header {
  padding-top: 3rem;
  padding-bottom: 1.5rem;

  :global(h1) {
    font-weight: 100;
    font-size: 2rem;
  }
}

.product-body {
  margin-bottom: 3rem;

  .bar {
    width: 100%;
    margin-bottom: 2.5rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    :global(button) {
      width: 100%;
      height: 3rem;
    }

    @include media-breakpoint-up(lg) {
      flex-direction: row;

      :global(button) {
        width: 9.2rem;
        min-width: 9.2rem;
      }
    }

    .search-bar {
      margin-bottom: 0.5rem;

      @include media-breakpoint-up(lg) {
        margin-bottom: 0;
      }
    }

    .select-category {
      border-color: $gray-900;
      color: $gray-900;
      height: 3rem;
      margin-bottom: 0.5rem;
      cursor: pointer;

      @include media-breakpoint-up(lg) {
        margin-bottom: 0;
        margin-left: 0.5rem;
        width: 16rem;
        min-width: 16rem;
      }
    }

    .select-status {
      border-color: $gray-900;
      color: $gray-900;
      height: 3rem;
      margin-bottom: 0.5rem;
      cursor: pointer;

      @include media-breakpoint-up(lg) {
        margin-bottom: 0;
        margin-right: 0.5rem;
        margin-left: 0.5rem;
        width: 9rem;
        min-width: 9rem;
      }
    }
  }

  .scroll-top {
    display: none;
    position: fixed;
    bottom: 5rem;
    right: 5rem;
    z-index: 5;
    height: 3rem;
    width: 3rem;
    border-radius: 0.5rem;
    background-color: $yellow-500;
    box-shadow: 3px 3px 10px -4px rgb(0 0 0 / 25%);
    justify-content: center;
    align-items: center;
    color: $gray-100;

    &:hover {
      cursor: pointer;
      background-color: $yellow-400;
    }

    @include media-breakpoint-up(lg) {
      display: flex;
    }

    .scroll-icon {
      font-size: 1.4rem;
    }
  }

  // .product-wrapper {}

  .no-found {
    text-align: center;
    color: $gray-500;
    font-weight: 300;
    margin: 5.5rem 0;
  }
}
