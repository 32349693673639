@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/breakpoints";

.toast-container {
  top: 96px;
  right: 50%;
  transform: translateX(50%);
  z-index: 50 !important;

  @include media-breakpoint-up(lg) {
    right: 185px;
  }
}
