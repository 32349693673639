@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

.month-picker-container {
  position: relative;

  .month-picker {
    border: 1px solid $gray-400;
    border-radius: 0.3rem;
    padding: 0.5rem 0.75rem;
    width: 100%;
  }

  .month-picker-icon {
    color: $gray-500;
    position: absolute;
    right: 0.75rem;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
  }
}

.delete-record {
  color: $red-500;
  cursor: pointer;
}
