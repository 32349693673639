@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/breakpoints";

.customer-header {
  padding-top: 3rem;
  padding-bottom: 1.5rem;

  :global(h1) {
    font-weight: 100;
    font-size: 2rem;
  }
}

.customer-body {
  .bar {
    width: 100%;
    margin-bottom: 2.5rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    :global(button) {
      width: 100%;
      height: 3rem;
    }

    @include media-breakpoint-up(lg) {
      flex-direction: row;

      :global(button) {
        width: 8.2rem;
        min-width: 8.2rem;
      }
    }

    .search-bar {
      margin-bottom: 0.5rem;

      @include media-breakpoint-up(lg) {
        margin-bottom: 0;
      }
    }

    .select-relationship {
      border-color: $blue-500;
      color: $blue-500;
      height: 3rem;
      margin-bottom: 0.5rem;
      cursor: pointer;

      @include media-breakpoint-up(lg) {
        margin-bottom: 0;
        margin-right: 0.5rem;
        margin-left: 0.5rem;
        width: 9.2rem;
        min-width: 9.2rem;
      }
    }
  }

  .no-found {
    text-align: center;
    color: $gray-500;
    font-weight: 300;
    margin: 5.5rem 0;
  }
}
