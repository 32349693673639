@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

.header-container {
  :global(a) {
    text-decoration: none;
    color: #000;

    &:hover {
      color: #fff;
    }
  }

  :global(.dropdown-menu) {
    background-color: $yellow-200;
    transform: translateX(-25%);
  }

  :global(.dropdown-item) {
    cursor: pointer;

    &:hover {
      background-color: $yellow-400;
    }

    a:hover {
      color: #000;
    }
  }

  .header-brand {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 600;
    cursor: pointer;
  }

  .sign-out {
    cursor: pointer;
  }

  .user-icon {
    font-size: 1.3rem;
    margin-right: 0.2rem;
  }
}

.content-wrapper {
  overflow: auto;
  height: calc(100vh - 76px);
  scroll-behavior: smooth;
}
