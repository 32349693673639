@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/breakpoints";

.category-form-container {
  display: flex;
  flex-direction: column;
  width: 100%;

  @include media-breakpoint-up(lg) {
    flex-direction: row;
  }

  .image-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 2rem;

    @include media-breakpoint-up(lg) {
      width: 40%;
    }

    .image-wrapper {
      width: 90%;

      @include media-breakpoint-up(lg) {
        width: 60%;
      }
    }

    .upload-button {
      margin-top: 2rem;
      width: 9rem;
      border-radius: 2rem;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
    }
  }

  .input-container {
    @include media-breakpoint-up(lg) {
      width: 60%;
      padding-left: 1rem;
    }

    .category-form-footer {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      margin-top: 1rem;

      @include media-breakpoint-up(lg) {
        flex-direction: row;
      }

      .delete-button {
        width: 100%;
        height: 2.8rem;
        margin-bottom: 0.5rem;

        @include media-breakpoint-up(lg) {
          width: 10rem;
          margin-bottom: 0;
          margin-right: 1rem;
        }
      }

      .submit-button {
        width: 100%;
        height: 2.8rem;

        @include media-breakpoint-up(lg) {
          width: 10rem;
        }
      }
    }
  }
}
