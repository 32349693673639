@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/breakpoints";

.dropzone-uploader {
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  border: 1px solid $gray-300;
  border-radius: 0.5rem;
  height: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    border: 1px dashed $gray-700;
  }

  &.drag {
    border: 1px dashed $gray-700;
  }

  :global(p) {
    color: $gray-500;
    margin-bottom: 0;
  }
}
