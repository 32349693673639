@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

.sign-in-container {
  width: 700px;
  min-height: 400px;
  max-width: 90%;
  position: absolute;
  top: calc(50% + 38px);
  left: 50%;
  transform: translate(-50%, -50%);

  @media (max-height: 500px) {
    position: static;
    transform: translate(0, 0);
    margin: (50px auto);
  }
  .sign-in-card {
    border-radius: 1rem;
    padding: 0.5rem;

    :global(.card-header) {
      padding: 2.2rem 1rem 0.75rem;
      background-color: #fff;
      border-bottom: none;
      text-align: center;
      font-size: 1.75rem;
      font-weight: 300;
    }

    :global(.card-footer) {
      background-color: #fff;
      border-top: none;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
    }

    .password-input-group {
      .password-input {
        padding: 0.375rem 2rem 0.375rem 0.75rem;
      }

      :global(.is-invalid).password-input {
        padding: 0.375rem 3.55rem 0.375rem 0.75rem;
      }

      .visibility {
        position: absolute;
        top: 1.9rem;
        right: 0.3rem;
        font-size: 1.5rem;
        color: $gray-600;
        cursor: pointer;

        &.invalid {
          right: 2rem;
          color: $red-500;
        }
      }
    }

    .sign-in-button {
      height: 3.125rem;
      width: 9rem;
    }
  }
}
