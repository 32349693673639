@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/breakpoints";

.product-item-container {
  position: relative;
  box-shadow: 0 6px 10px -4px rgb(0 0 0 / 15%);
  border-radius: 1rem;
  height: 400px;
  width: 320px;
  overflow: hidden;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
  flex-shrink: 0;

  &:hover {
    box-shadow: 0 12px 19px -7px rgb(0 0 0 / 30%);
  }

  .move {
    position: absolute;
    top: 0.6rem;
    right: 0.6rem;
    padding: 0.5rem;
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 10%);
    border-radius: 0.2rem;
    background-color: #fff;
    
    @include media-breakpoint-up(lg) {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &:hover {
      background-color: $gray-100;
      cursor: move;
    }
  }

  :global(img) {
    width: 100%;
    height: 12.5rem;
    object-fit: contain;
    user-select: none;
  }

  .product-item-info {
    padding: 1rem;

    :global(p) {
      font-size: 0.9rem;
      margin-bottom: 0.5rem;
    }

    .product-item-info__name {
      font-weight: bold;
      font-size: 1.3rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-bottom: 1rem;
    }

    .product-item-info__description {
      color: $gray-600;
      margin-bottom: 1rem;
      font-size: 1rem;
      height: 2.8rem;
      word-wrap: break-word;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
