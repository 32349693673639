@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/breakpoints";

.category-item-container {
  box-shadow: 0 6px 10px -4px rgb(0 0 0 / 15%);
  border-radius: 1rem;
  height: 16rem;
  width: 100%;
  max-width: 20rem;
  overflow: hidden;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: transform 0.3s cubic-bezier(0.34, 2, 0.6, 1);

  &:hover {
    box-shadow: 0 12px 19px -7px rgb(0 0 0 / 30%);
    transform: translateY(-10px);
  }

  :global(img) {
    width: 100%;
    height: 12.5rem;
    object-fit: contain;
  }

  .category-item-description {
    position: relative;
    padding-left: 1.5rem;
    padding-right: 1.5rem;

    :global(p) {
      text-align: center;
      font-size: 1.2rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .category-item-edit {
      position: absolute;
      top: 0.5rem;
      right: 0.5rem;
      cursor: pointer;
      z-index: 1;

      &:hover {
        color: $gray-600;
      }
    }
  }
}
