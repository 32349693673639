@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/breakpoints";

.row-container {
  display: flex;
  border-bottom: 1.7px solid $gray-400;
  min-height: 4.5rem;

  &:last-child {
    border-bottom: none;
  }

  .col-index {
    width: 8%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $gray-200;
    position: relative;
  }

  .col-content {
    width: 65%;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    padding: 0.5rem;

    &:hover {
      background-color: $gray-100;
    }

    @include media-breakpoint-up(lg) {
      width: 70%;
    }

    .col-content_1 {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;

      @include media-breakpoint-up(lg) {
        width: 80%;
      }

      .name {
        margin-bottom: 0;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      .total_unbilled_amount {
        margin-bottom: 0;
        font-size: 0.75rem;
      }
    }

    .col-content_2 {
      font-size: 0.7rem;
      text-align: end;
      color: $gray-700;
    }
  }

  .col-action {
    width: 27%;
    display: flex;

    @include media-breakpoint-up(lg) {
      width: 22%;
    }

    .btn {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-size: 1.3rem;
      color: $gray-700;
      border: none;

      &:nth-child(1) {
        background-color: $orange-400;
        color: #fff;

        &:hover {
          background-color: $orange-300;
        }
      }

      &:nth-child(2) {
        background-color: $blue-400;
        color: #fff;

        &:hover {
          background-color: $blue-300;
        }
      }

      &:nth-child(3) {
        background-color: $gray-700;
        color: #fff;

        &:hover {
          background-color: $gray-600;
        }
      }

      :global(span) {
        font-size: 0.8rem;
        margin-top: 0.25rem;
        text-align: center;
      }
    }
  }
}

.row-container.suspended {
  .col-index {
    background-color: $red-400;
  }

  .col-content {
    background-color: $red-100;

    &:hover {
      background-color: $red-200;
    }
  }
}
