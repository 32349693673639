@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/breakpoints";

.category-header {
  padding-top: 3rem;
  text-align: center;

  @include media-breakpoint-up(lg) {
    padding-bottom: 0;
  }

  :global(h1) {
    font-weight: 100;
    font-size: 1.8rem;
  }
}

.category-body {
  margin-top: 3rem;
  margin-bottom: 3rem;
  display: grid;
  grid-template-columns: repeat(auto-fill, 20rem);
  column-gap: 3rem;
  row-gap: 3rem;
  justify-content: center;

  @include media-breakpoint-up(lg) {
    justify-content: space-evenly;
  }

  .add-category-container {
    box-shadow: 0 6px 10px -4px rgb(0 0 0 / 15%);
    border-radius: 1rem;
    height: 16rem;
    width: 100%;
    max-width: 20rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: relative;

    .add-category-wrapper {
      border: 1px dashed $gray-600;
      border-radius: 1rem;
      width: 90%;
      height: 90%;
      display: flex;
      flex-direction: column;
      align-items: center;

      .plus-sign-wrapper {
        padding-top: 3.5rem;
        padding-bottom: 3.5rem;

        .plus-sign {
          color: $gray-600;
          font-size: 5rem;
        }
      }
    }

    .add-category-backdrop {
      position: absolute;
      z-index: 2;
      height: 100%;
      width: 100%;
      border-radius: 1rem;
      background-color: rgba(255, 255, 255, 0.25);
      display: none;
    }

    &:hover .add-category-backdrop {
      display: block;
    }
  }
}
